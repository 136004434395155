import { useEffect, useRef } from 'react'
import { MobileHeader } from '@/components/navigation/mobile-header'
import { MobileNavigation } from '@/components/navigation/mobile-navigation'
import { Outlet, useLocation } from 'react-router-dom'

export function MobileLayout() {
  const location = useLocation()
  const mainRef = useRef<HTMLElement>(null)

  useEffect(() => {
    // Reset scroll position when route changes
    if (mainRef.current) {
      mainRef.current.scrollTo({ top: 0, behavior: 'instant' })
      
      // Also reset any page-content containers
      const pageContent = mainRef.current.querySelector('.page-content')
      if (pageContent) {
        pageContent.scrollTo({ top: 0, behavior: 'instant' })
      }
    }
  }, [location.pathname])

  return (
    <div className="flex flex-col min-h-screen bg-background">
      <MobileHeader />
      
      <main 
        ref={mainRef}
        className="flex-1 overflow-y-auto scrollbar-hide pt-header pb-nav"
      >
        <div className="mobile-container">
          <Outlet />
        </div>
      </main>

      <MobileNavigation />
    </div>
  )
}