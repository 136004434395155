import { useSearchParams, useNavigate } from 'react-router-dom'
import { Card, CardBody, Button, Spinner, Input } from '@nextui-org/react'
import { useDailyMoods } from '@/hooks/use-daily-moods'
import { DayMoodChart } from '@/components/mood/day-mood-chart'
import { MoodEntryList } from '@/components/mood/mood-entry-list'
import { CalendarIcon, ChartBarIcon, PlusCircleIcon } from '@heroicons/react/24/outline'
import { PRINCIPLES, COMPONENT_STYLES } from '@/lib/constants'
import { useEffect } from 'react'
import { PageContainer } from '@/components/ui/page-container'

function DayPage() {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  
  // Format today's date as YYYY-MM-DD
  const today = new Date().toISOString().split('T')[0]
  
  // Use the date from URL params, fallback to today
  const date = searchParams.get('date') || today
  
  // Ensure the date is not in the future
  useEffect(() => {
    if (date > today) {
      setSearchParams({ date: today })
    }
  }, [date, today, setSearchParams])

  const { data: entries, isLoading } = useDailyMoods(date)
  console.log('Daily entries:', entries, 'isLoading:', isLoading) // Debug log

  function handleDateChange(newDate: string) {
    setSearchParams({ date: newDate })
  }

  function handlePreviousDay() {
    const newDate = new Date(date)
    newDate.setDate(newDate.getDate() - 1)
    setSearchParams({ date: newDate.toISOString().split('T')[0] })
  }

  function handleNextDay() {
    const newDate = new Date(date)
    newDate.setDate(newDate.getDate() + 1)
    setSearchParams({ date: newDate.toISOString().split('T')[0] })
  }

  return (
    <PageContainer>
      <div className="space-y-6">
        {/* Date Navigation */}
        <div className="glass-panel rounded-3xl p-4 bg-gradient-to-br from-primary/10 to-primary/5 backdrop-blur-xl">
          <div className="flex items-center justify-between gap-4">
            <Button
              className="w-10 h-10 rounded-xl bg-foreground/5 hover:bg-foreground/10"
              onClick={handlePreviousDay}
              isIconOnly
              variant="light"
              aria-label="Previous Day"
            >
              <div className="transition-transform duration-200 group-hover:-translate-x-0.5">←</div>
            </Button>

            <div 
              className="relative min-w-[200px] cursor-pointer overflow-hidden rounded-xl 
                        border border-white/[0.05] bg-white/[0.02] backdrop-blur-xl
                        hover:bg-white/[0.04] transition-colors"
              onClick={() => (document.getElementById('date-picker') as HTMLInputElement).focus()}
              role="button"
              aria-label="Select Date"
            >
              <Input
                id="date-picker"
                type="date"
                value={date}
                onChange={(e) => handleDateChange(e.target.value)}
                max={new Date().toISOString().split('T')[0]}
                aria-label="Date Selection"
                classNames={{
                  base: "max-w-full",
                  mainWrapper: "h-full",
                  input: "text-center bg-gradient-to-r from-primary to-primary/60 bg-clip-text text-transparent font-semibold cursor-pointer",
                  inputWrapper: "h-full bg-transparent border-none hover:bg-transparent",
                }}
              />
              <div className="absolute -left-1 top-1/2 h-2 w-2 -translate-y-1/2 rounded-full bg-primary/50" />
              <div className="absolute -right-1 top-1/2 h-2 w-2 -translate-y-1/2 rounded-full bg-secondary/50" />
            </div>

            <Button
              className="w-10 h-10 rounded-xl bg-foreground/5 hover:bg-foreground/10"
              onClick={handleNextDay}
              isDisabled={new Date(date) >= new Date()}
              isIconOnly
              variant="light"
              aria-label="Next Day"
            >
              <div className="transition-transform duration-200 group-hover:translate-x-0.5">→</div>
            </Button>
          </div>
        </div>

        {/* Chart Section */}
        <div className="glass-panel rounded-3xl bg-gradient-to-b from-background/80 to-background/40 backdrop-blur-xl">
          <div className="flex items-center justify-between p-4 border-b border-foreground/5">
            <h2 className="text-xl font-semibold bg-gradient-to-r from-primary to-primary/60 bg-clip-text text-transparent">
              Daily Overview
            </h2>
            <Button
              className="bg-primary/10 hover:bg-primary/20 text-primary rounded-xl h-10"
              size="sm"
              onClick={() => navigate(`/input?selectedDate=${date}`)}
              startContent={<PlusCircleIcon className="h-4 w-4" />}
            >
              Add Entry
            </Button>
          </div>

          <div className="p-4">
            {isLoading ? (
              <div className="flex h-[400px] items-center justify-center">
                <Spinner size="lg" color="primary" />
              </div>
            ) : entries && entries.length > 0 ? (
              <div className="space-y-6">
                <div className="h-[400px]">
                  <DayMoodChart entries={entries} />
                </div>
                <div className="glass-panel rounded-2xl p-4 bg-gradient-to-br from-background/60 to-background/20 backdrop-blur-xl">
                  <MoodEntryList entries={entries} date={date} />
                </div>
              </div>
            ) : (
              <div className="flex flex-col h-[400px] items-center justify-center space-y-4">
                <div className="text-foreground/60 text-center">
                  <p className="text-lg font-medium">No entries for this day</p>
                  <p className="text-sm">Add your first mood entry to start tracking</p>
                </div>
                <Button
                  className="bg-primary/10 hover:bg-primary/20 text-primary rounded-xl h-12 px-6"
                  onClick={() => navigate(`/input?selectedDate=${date}`)}
                  startContent={<PlusCircleIcon className="h-5 w-5" />}
                >
                  Add Entry
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </PageContainer>
  )
}

export default DayPage