import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Input } from '@nextui-org/react'
import { PageTransition } from '@/components/ui/page-transition'
import { PageContainer } from '@/components/ui/page-container'
import { 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword,
} from 'firebase/auth'
import { auth } from '@/lib/utils/firebase'
import { SignInForm } from '@/components/auth/sign-in-form'
import { SignUpForm } from '@/components/auth/sign-up-form'

export default function AuthPage() {
  const [mode, setMode] = useState<'signin' | 'signup'>('signin')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setError(null)
    setIsLoading(true)

    try {
      if (mode === 'signin') {
        await signInWithEmailAndPassword(auth, email, password)
      } else {
        await createUserWithEmailAndPassword(auth, email, password)
      }
      navigate('/home')
    } catch (error: any) {
      console.error('Auth error:', error)
      if (error.code === 'auth/invalid-credential') {
        setError('Invalid email or password')
      } else if (error.code === 'auth/email-already-in-use') {
        setError('An account with this email already exists')
      } else if (error.code === 'auth/invalid-email') {
        setError('Please enter a valid email address')
      } else if (error.code === 'auth/weak-password') {
        setError('Password should be at least 6 characters')
      } else {
        setError('An error occurred. Please try again.')
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <PageContainer>
      <PageTransition>
        <div className="flex flex-col min-h-screen items-center justify-center p-4">
          <div className="w-full max-w-md space-y-6">
            {/* Logo/Header */}
            <div className="text-center space-y-2">
              <h1 className="text-4xl font-bold bg-gradient-to-r from-primary to-primary/60 bg-clip-text text-transparent">
                Mood Tracker
              </h1>
              <p className="text-foreground/60">Track your emotional journey</p>
            </div>

            {/* Auth Form */}
            <div className="glass-panel rounded-3xl p-6 space-y-6 bg-gradient-to-b from-background/80 to-background/40 backdrop-blur-xl">
              <div className="flex gap-4">
                <Button
                  className={`flex-1 h-12 ${
                    mode === 'signin' 
                      ? 'bg-primary/10 text-primary'
                      : 'bg-foreground/5 text-foreground/60'
                  } rounded-xl backdrop-blur-xl transition-colors`}
                  onClick={() => setMode('signin')}
                >
                  Sign In
                </Button>
                <Button
                  className={`flex-1 h-12 ${
                    mode === 'signup'
                      ? 'bg-primary/10 text-primary'
                      : 'bg-foreground/5 text-foreground/60'
                  } rounded-xl backdrop-blur-xl transition-colors`}
                  onClick={() => setMode('signup')}
                >
                  Sign Up
                </Button>
              </div>

              {mode === 'signin' ? (
                <SignInForm 
                  email={email} 
                  password={password} 
                  onChangeEmail={(e) => setEmail(e.target.value)} 
                  onChangePassword={(e) => setPassword(e.target.value)} 
                  onSubmit={handleSubmit} 
                  error={error} 
                  isLoading={isLoading} 
                />
              ) : (
                <SignUpForm 
                  email={email} 
                  password={password} 
                  onChangeEmail={(e) => setEmail(e.target.value)} 
                  onChangePassword={(e) => setPassword(e.target.value)} 
                  onSubmit={handleSubmit} 
                  error={error} 
                  isLoading={isLoading} 
                />
              )}
            </div>
          </div>
        </div>
      </PageTransition>
    </PageContainer>
  )
}