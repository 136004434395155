import { User } from 'firebase/auth';
import { loadStripe } from '@stripe/stripe-js';
import { auth, db, functions } from '@/lib/utils/firebase';
import { collection, doc, getDoc, getDocs, setDoc, onSnapshot, updateDoc, serverTimestamp, DocumentSnapshot } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { httpsCallable } from 'firebase/functions';

// Initialize Stripe
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);

// Types
export interface SubscriptionStatus {
  isActive: boolean;
  periodEnd?: number;
  cancelAtPeriodEnd?: boolean;
}

interface CheckoutSessionData {
  sessionId: string;
  url?: string;
  error?: {
    message: string;
  };
}

interface PortalSessionData {
  url: string;
}

// Check if we're in development mode
const isDevelopment = import.meta.env.DEV;

// Helper function to simulate async delay
const simulateDelay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const stripe = {
  // Get Stripe instance
  getInstance: async () => {
    return await stripePromise;
  },

  // Create checkout session
  createCheckoutSession: async (priceId: string): Promise<string> => {
    const user = auth.currentUser;
    if (!user) throw new Error('User must be logged in');

    if (isDevelopment) {
      // Show loading toast
      const toastId = toast.loading('Creating test subscription...');

      // In development, directly create a mock subscription
      const subscriptionRef = doc(db, 'users', user.uid, 'subscriptions', 'subscription');
      
      // Simulate a small delay to make it feel more realistic
      await simulateDelay(1500);
      
      await setDoc(subscriptionRef, {
        status: 'active',
        current_period_end: { seconds: Math.floor(Date.now() / 1000) + 30 * 24 * 60 * 60 }, // 30 days from now
        cancel_at_period_end: false,
      });

      // Update toast and reload
      toast.update(toastId, { 
        render: 'Subscription activated! (Development Mode)', 
        type: 'success', 
        isLoading: false,
        autoClose: 2000
      });
      
      // Reload after toast
      setTimeout(() => window.location.reload(), 2000);
      return 'Subscription activated (Development Mode)';
    }

    try {
      // Ensure customer document exists
      const customerRef = doc(db, 'customers', user.uid);
      const customerDoc = await getDoc(customerRef);
      
      if (!customerDoc.exists()) {
        console.log('Creating customer document...');
        try {
          await setDoc(customerRef, {
            uid: user.uid,
            email: user.email,
            created: serverTimestamp()
          });
          console.log('Customer document created successfully');
        } catch (error) {
          console.error('Error creating customer document:', error);
          throw new Error('Failed to create customer record. Please try again later.');
        }
      }

      // Create a new checkout session in the subcollection
      console.log('Creating checkout session...');
      const checkoutSessionRef = doc(collection(customerRef, 'checkout_sessions'));

      // Set the checkout session data
      const sessionData = {
        price: priceId,
        success_url: window.location.origin + '/settings?success=true',
        cancel_url: window.location.origin + '/settings?canceled=true',
        mode: 'subscription',
        payment_method_types: ['card'],
        metadata: {
          userId: user.uid
        },
        client_reference_id: user.uid
      };

      console.log('Creating checkout session with data:', sessionData);
      try {
        await setDoc(checkoutSessionRef, sessionData);
        console.log('Checkout session document created at path:', checkoutSessionRef.path);
      } catch (error) {
        console.error('Error creating checkout session document:', error);
        throw new Error('Failed to create checkout session. Please verify Firestore permissions.');
      }

      // Wait for the redirect URL with improved error handling
      return new Promise((resolve, reject) => {
        let timeoutId: NodeJS.Timeout;
        const unsubscribe = onSnapshot(
          checkoutSessionRef,
          async (snap: DocumentSnapshot) => {
            const data = snap.data() as CheckoutSessionData;
            console.log('Checkout session update:', { 
              id: snap.id,
              path: snap.ref.path,
              exists: snap.exists(),
              data: data,
              hasUrl: !!data?.url,
              hasError: !!data?.error,
              hasSessionId: !!data?.sessionId
            });
            
            if (data?.error) {
              console.error('Checkout session error:', data.error);
              clearTimeout(timeoutId);
              unsubscribe();
              reject(new Error(data.error.message || 'An error occurred with the checkout session'));
            }
            if (data?.url) {
              console.log('Received checkout URL:', data.url);
              clearTimeout(timeoutId);
              unsubscribe();
              resolve(data.url);
            }
            if (data?.sessionId) {
              console.log('Received session ID:', data.sessionId);
              const stripe = await stripePromise;
              if (stripe) {
                clearTimeout(timeoutId);
                unsubscribe();
                window.location.href = data.url || '';
              }
            }
          },
          (error) => {
            console.error('Snapshot error:', error);
            clearTimeout(timeoutId);
            unsubscribe();
            reject(error);
          }
        );

        // Set timeout for 15 seconds
        timeoutId = setTimeout(() => {
          console.log('Checkout session timed out. Debug info:', {
            checkoutSessionPath: checkoutSessionRef.path,
            userId: user.uid,
            priceId: priceId,
            extensionPath: 'customers/' + user.uid + '/checkout_sessions'
          });
          unsubscribe();
          reject(new Error('Checkout session creation timed out. Please verify that:\n1. The Stripe extension is installed in Firebase\n2. The extension service account has proper permissions\n3. The price ID is correct and active'));
        }, 15000);
      });
    } catch (error) {
      console.error('Error in createCheckoutSession:', error);
      throw error;
    }
  },

  // Get subscription status
  getSubscriptionStatus: async (): Promise<SubscriptionStatus> => {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        return { isActive: false };
      }

      // Check if user has lifetime premium
      const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
      const userData = userDoc.data();
      
      if (userData?.lifetimePremium === true) {
        return { isActive: true };
      }

      // Check for subscription in customers collection
      const subscriptionsRef = collection(db, 'customers', currentUser.uid, 'subscriptions');
      const subscriptionsSnap = await getDocs(subscriptionsRef);

      if (subscriptionsSnap.empty) {
        return { isActive: false };
      }

      // Get the first active subscription
      const subscription = subscriptionsSnap.docs
        .map(doc => doc.data())
        .find(sub => sub.status === 'active');

      if (!subscription) {
        return { isActive: false };
      }

      return {
        isActive: subscription.status === 'active',
        periodEnd: subscription.current_period_end?.seconds,
        cancelAtPeriodEnd: subscription.cancel_at_period_end
      };
    } catch (error) {
      console.error('Error checking subscription status:', error);
      return { isActive: false };
    }
  },

  // Create portal session
  createPortalSession: async (user: any): Promise<string> => {
    try {
      // First, get the customer ID from the customer document
      const customerDoc = await getDoc(doc(db, 'customers', user.uid));
      if (!customerDoc.exists()) {
        throw new Error('Customer document not found. Please make sure you have an active subscription.');
      }
      const customerData = customerDoc.data();
      const stripeCustomerId = customerData.stripeId || customerData.stripe_customer_id;
      
      if (!stripeCustomerId) {
        throw new Error('Stripe customer ID not found. Please make sure you have an active subscription.');
      }

      console.log('Found Stripe customer ID:', stripeCustomerId);

      // Create a Functions callable
      const createPortalLink = httpsCallable<
        { returnUrl: string, locale: string },
        { url: string }
      >(functions, 'ext-firestore-stripe-payments-createPortalLink');
      
      // Call the function with portal configuration
      const { data } = await createPortalLink({
        returnUrl: window.location.origin,
        locale: "auto" // Optional, defaults to "auto"
      });

      // The response should contain the URL
      if (data && data.url) {
        // Add prefilled email to the portal URL
        const portalUrl = new URL(data.url);
        if (user.email) {
          portalUrl.searchParams.append('prefilled_email', encodeURIComponent(user.email));
        }
        
        // Redirect to the portal URL with prefilled email
        window.location.assign(portalUrl.toString());
        return portalUrl.toString();
      } else {
        throw new Error('No portal URL returned from Stripe');
      }
    } catch (error) {
      console.error('Error creating portal session:', error);
      throw error;
    }
  },

  // Alias for createPortalSession to maintain backward compatibility
  createPortalLink: async (user: any): Promise<string> => {
    return stripe.createPortalSession(user);
  },

  // Set lifetime premium status
  setLifetimePremium: async (userId: string, enabled: boolean = true) => {
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        lifetimePremium: enabled,
        lifetimePremiumUpdatedAt: serverTimestamp()
      });
      return true;
    } catch (error) {
      console.error('Error setting lifetime premium status:', error);
      throw error;
    }
  }
};