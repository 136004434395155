import { useNavigate } from 'react-router-dom'
import { Button } from '@nextui-org/react'
import { formatDistanceToNow } from 'date-fns'
import {
  ChartBarIcon,
  DocumentTextIcon,
  FireIcon,
  ClockIcon,
  Cog6ToothIcon,
  ArrowLeftOnRectangleIcon
} from '@heroicons/react/24/outline'
import { PageTransition } from '@/components/ui/page-transition'
import { PageContainer } from '@/components/ui/page-container'
import { StatCard } from '@/components/profile/stat-card'
import { UserAvatar } from '@/components/profile/user-avatar'
import { useAuth } from '@/stores/auth-context'
import { useMoodStats } from '@/hooks/use-mood-stats'
import { auth } from '@/lib/utils/firebase'
import { StorageIndicator } from '@/components/subscription/storage-indicator'
import type { MoodStats } from '@/types/mood'

export default function ProfilePage() {
  const navigate = useNavigate()
  const { user } = useAuth()
  const { data: stats } = useMoodStats()

  const handleSignOut = async () => {
    try {
      await auth.signOut()
      navigate('/auth')
    } catch (error) {
      console.error('Error signing out:', error)
    }
  }

  const formatLastEntry = (lastEntry: Date | null) => {
    if (!lastEntry) return 'Never'
    return formatDistanceToNow(lastEntry, { addSuffix: true })
  }

  return (
    <PageContainer>
      <PageTransition>
        <div className="flex flex-col space-y-6">
          {/* User Info Panel */}
          <div className="glass-panel rounded-3xl p-6 bg-gradient-to-b from-background/80 to-background/40 backdrop-blur-xl">
            <UserAvatar 
              email={user?.email || ''} 
              displayName={user?.displayName}
              photoURL={user?.photoURL}
              onUpdate={() => auth.currentUser?.reload()}
            />
          </div>

          {/* Stats Grid */}
          <div className="grid grid-cols-2 gap-4">
            <div className="glass-panel rounded-3xl p-4 bg-gradient-to-br from-primary/10 to-primary/5 backdrop-blur-xl">
              <div className="flex items-end justify-between">
                <div>
                  <p className="text-sm text-foreground/60 font-medium">30 Day Average</p>
                  <p className="text-3xl font-bold bg-gradient-to-r from-primary to-primary/60 bg-clip-text text-transparent">
                    {stats?.thirtyDayAverage?.toFixed(1) || '-'}
                  </p>
                </div>
                <div className="h-8 w-8 rounded-xl bg-primary/10 flex items-center justify-center">
                  <ChartBarIcon className="h-5 w-5 text-primary" />
                </div>
              </div>
            </div>

            <div className="glass-panel rounded-3xl p-4 bg-gradient-to-br from-secondary/10 to-secondary/5 backdrop-blur-xl">
              <div className="flex items-end justify-between">
                <div>
                  <p className="text-sm text-foreground/60 font-medium">Total Entries</p>
                  <p className="text-3xl font-bold bg-gradient-to-r from-secondary to-secondary/60 bg-clip-text text-transparent">
                    {stats?.totalEntries || 0}
                  </p>
                </div>
                <div className="h-8 w-8 rounded-xl bg-secondary/10 flex items-center justify-center">
                  <DocumentTextIcon className="h-5 w-5 text-secondary" />
                </div>
              </div>
            </div>

            <div className="glass-panel rounded-3xl p-4 bg-gradient-to-br from-blue-500/10 to-blue-500/5 backdrop-blur-xl">
              <div className="flex items-end justify-between">
                <div>
                  <p className="text-sm text-foreground/60 font-medium">Current Streak</p>
                  <p className="text-3xl font-bold bg-gradient-to-r from-blue-500 to-blue-500/60 bg-clip-text text-transparent">
                    {stats?.currentStreak || 0}
                    <span className="text-lg ml-1">{stats?.currentStreak === 1 ? 'day' : 'days'}</span>
                  </p>
                </div>
                <div className="h-8 w-8 rounded-xl bg-blue-500/10 flex items-center justify-center">
                  <FireIcon className="h-5 w-5 text-blue-500" />
                </div>
              </div>
            </div>

            <div className="glass-panel rounded-3xl p-4 bg-gradient-to-br from-purple-500/10 to-purple-500/5 backdrop-blur-xl">
              <div className="flex items-end justify-between">
                <div>
                  <p className="text-sm text-foreground/60 font-medium">Last Entry</p>
                  <p className="text-3xl font-bold bg-gradient-to-r from-purple-500 to-purple-500/60 bg-clip-text text-transparent">
                    {formatLastEntry(stats?.lastEntry || null)}
                  </p>
                </div>
                <div className="h-8 w-8 rounded-xl bg-purple-500/10 flex items-center justify-center">
                  <ClockIcon className="h-5 w-5 text-purple-500" />
                </div>
              </div>
            </div>
          </div>

          {/* Storage Indicator for free users */}
          <StorageIndicator />

          {/* Actions */}
          <div className="space-y-4">
            <Button
              className="w-full h-14 bg-primary/10 hover:bg-primary/20 text-primary rounded-3xl glass-panel backdrop-blur-xl"
              startContent={<Cog6ToothIcon className="h-5 w-5" />}
              onClick={() => navigate('/settings')}
            >
              Settings
            </Button>
            <Button
              className="w-full h-14 bg-danger/10 hover:bg-danger/20 text-danger rounded-3xl glass-panel backdrop-blur-xl"
              startContent={<ArrowLeftOnRectangleIcon className="h-5 w-5" />}
              onClick={handleSignOut}
            >
              Sign Out
            </Button>
          </div>
        </div>
      </PageTransition>
    </PageContainer>
  )
}
