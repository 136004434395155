import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PageTransition } from '@/components/ui/page-transition'
import { PageContainer } from '@/components/ui/page-container'
import { Button, Input, Switch, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter } from '@nextui-org/react'
import { 
  BellIcon, MoonIcon, GlobeAltIcon, 
  ShieldCheckIcon, KeyIcon, EnvelopeIcon,
  ArrowLeftIcon
} from '@heroicons/react/24/outline'
import { auth } from '@/lib/utils/firebase'
import { 
  deleteUser, 
  EmailAuthProvider, 
  reauthenticateWithCredential,
  updateEmail,
  updatePassword,
  AuthError 
} from 'firebase/auth'
import { motion } from 'framer-motion'
import { useTheme } from '@/stores/theme-context'
import { SubscriptionCard } from '@/components/subscription/subscription-card'

export default function SettingsPage() {
  const navigate = useNavigate()
  const { theme, toggleTheme } = useTheme()
  const [notifications, setNotifications] = useState(true)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isReauthModalOpen, setIsReauthModalOpen] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [deleteError, setDeleteError] = useState('')
  const [password, setPassword] = useState('')
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false)
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false)
  const [newEmail, setNewEmail] = useState('')
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [isUpdating, setIsUpdating] = useState(false)

  const handleReauthenticate = async () => {
    setIsDeleting(true)
    setDeleteError('')

    try {
      const user = auth.currentUser
      if (!user?.email) {
        setDeleteError('No user email found')
        return
      }

      const credential = EmailAuthProvider.credential(user.email, password)
      await reauthenticateWithCredential(user, credential)
      
      // After successful reauthentication, proceed with deletion
      await deleteUser(user)
      setIsReauthModalOpen(false)
      setIsDeleteModalOpen(false)
      navigate('/auth')
    } catch (error) {
      const authError = error as AuthError
      switch (authError.code) {
        case 'auth/wrong-password':
          setDeleteError('Incorrect password')
          break
        case 'auth/too-many-requests':
          setDeleteError('Too many attempts. Please try again later')
          break
        default:
          setDeleteError('Failed to verify credentials')
      }
    } finally {
      setIsDeleting(false)
      setPassword('') // Clear password field
    }
  }

  const handleDeleteAccount = async () => {
    setIsDeleting(true)
    setDeleteError('')

    try {
      const user = auth.currentUser
      if (!user) {
        setDeleteError('No user found')
        return
      }

      await deleteUser(user)
      setIsDeleteModalOpen(false)
      navigate('/auth')
    } catch (error) {
      const authError = error as AuthError
      if (authError.code === 'auth/requires-recent-login') {
        setIsReauthModalOpen(true)
        setIsDeleteModalOpen(false)
      } else {
        setDeleteError('Failed to delete account')
      }
    } finally {
      setIsDeleting(false)
    }
  }

  const handleEmailUpdate = async () => {
    setIsUpdating(true)
    setEmailError('')

    try {
      const user = auth.currentUser
      if (!user?.email) {
        setEmailError('No user email found')
        return
      }

      // First reauthenticate
      const credential = EmailAuthProvider.credential(user.email, currentPassword)
      await reauthenticateWithCredential(user, credential)
      
      // Then update email
      await updateEmail(user, newEmail)
      setIsEmailModalOpen(false)
      setNewEmail('')
      setCurrentPassword('')
    } catch (error) {
      const authError = error as AuthError
      switch (authError.code) {
        case 'auth/wrong-password':
          setEmailError('Incorrect password')
          break
        case 'auth/invalid-email':
          setEmailError('Invalid email format')
          break
        case 'auth/email-already-in-use':
          setEmailError('Email already in use')
          break
        default:
          setEmailError('Failed to update email')
      }
    } finally {
      setIsUpdating(false)
    }
  }

  const handlePasswordUpdate = async () => {
    setIsUpdating(true)
    setPasswordError('')

    if (newPassword !== confirmPassword) {
      setPasswordError('Passwords do not match')
      setIsUpdating(false)
      return
    }

    try {
      const user = auth.currentUser
      if (!user?.email) {
        setPasswordError('No user email found')
        return
      }

      // First reauthenticate
      const credential = EmailAuthProvider.credential(user.email, currentPassword)
      await reauthenticateWithCredential(user, credential)
      
      // Then update password
      await updatePassword(user, newPassword)
      setIsPasswordModalOpen(false)
      setCurrentPassword('')
      setNewPassword('')
      setConfirmPassword('')
    } catch (error) {
      const authError = error as AuthError
      switch (authError.code) {
        case 'auth/wrong-password':
          setPasswordError('Incorrect current password')
          break
        case 'auth/weak-password':
          setPasswordError('New password is too weak')
          break
        default:
          setPasswordError('Failed to update password')
      }
    } finally {
      setIsUpdating(false)
    }
  }

  return (
    <PageContainer>
      <PageTransition>
        <div className="flex flex-col space-y-6">
          {/* Subscription Card */}
          <SubscriptionCard />

          {/* Settings Items */}
          <SettingItem
            icon={<BellIcon className="h-5 w-5" />}
            title="Notifications"
            description="Enable or disable notifications"
          >
            <Switch
              isSelected={notifications}
              onValueChange={setNotifications}
              classNames={{
                wrapper: "bg-foreground/20 group-data-[selected=true]:bg-primary"
              }}
            />
          </SettingItem>

          <SettingItem
            icon={<MoonIcon className="h-5 w-5" />}
            title="Dark Mode"
            description="Toggle dark mode theme"
          >
            <Switch
              isSelected={theme === 'dark'}
              onValueChange={toggleTheme}
              classNames={{
                wrapper: "bg-foreground/20 group-data-[selected=true]:bg-primary"
              }}
            />
          </SettingItem>

          <div className="pt-4">
            <h2 className="text-lg font-semibold text-foreground/90 mb-4">Account Settings</h2>
            
            <div className="space-y-4">
              <SettingItem
                icon={<EnvelopeIcon className="w-6 h-6" />}
                title="Email"
                description={auth.currentUser?.email || 'No email set'}
              >
                <Button
                  variant="light"
                  color="primary"
                  size="sm"
                  className="hover-lift"
                  onClick={() => setIsEmailModalOpen(true)}
                >
                  Change
                </Button>
              </SettingItem>

              <SettingItem
                icon={<KeyIcon className="w-6 h-6" />}
                title="Password"
                description="Change your password"
              >
                <Button
                  variant="light"
                  color="primary"
                  size="sm"
                  className="hover-lift"
                  onClick={() => setIsPasswordModalOpen(true)}
                >
                  Update
                </Button>
              </SettingItem>

              <SettingItem
                icon={<ShieldCheckIcon className="w-6 h-6" />}
                title="Delete Account"
                description="Permanently delete your account"
              >
                <Button
                  color="danger"
                  variant="flat"
                  size="sm"
                  onClick={() => setIsDeleteModalOpen(true)}
                  className="hover-lift"
                >
                  Delete
                </Button>
              </SettingItem>
            </div>
          </div>
        </div>

        <Modal 
          isOpen={isDeleteModalOpen} 
          onClose={() => setIsDeleteModalOpen(false)}
          classNames={{
            base: "glass-morphism",
            header: "border-b border-white/10",
            body: "py-6",
            footer: "border-t border-white/10"
          }}
        >
          <ModalContent>
            <ModalHeader>
              <h3 className="text-xl font-semibold">Delete Account</h3>
            </ModalHeader>
            <ModalBody>
              <p className="text-foreground/70">
                Are you sure you want to delete your account? This action cannot be undone.
              </p>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="light"
                onPress={() => setIsDeleteModalOpen(false)}
              >
                Cancel
              </Button>
              <Button
                color="danger"
                variant="flat"
                onPress={() => {
                  setIsDeleteModalOpen(false)
                  setIsReauthModalOpen(true)
                }}
              >
                Delete Account
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal 
          isOpen={isReauthModalOpen} 
          onClose={() => !isDeleting && setIsReauthModalOpen(false)}
          classNames={{
            base: "glass-morphism",
            header: "border-b border-white/10",
            body: "py-6",
            footer: "border-t border-white/10"
          }}
        >
          <ModalContent>
            <ModalHeader>
              <h3 className="text-xl font-semibold">Confirm Password</h3>
            </ModalHeader>
            <ModalBody>
              <p className="text-foreground/70 mb-4">
                Please enter your password to confirm account deletion.
              </p>
              <Input
                type="password"
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                isDisabled={isDeleting}
                classNames={{
                  label: "text-foreground/70",
                  input: "bg-background/50 text-foreground",
                  inputWrapper: [
                    "bg-background/50",
                    "backdrop-blur-xl",
                    "hover:bg-background/60",
                    "group-data-[focused=true]:bg-background/70",
                    "!cursor-text",
                    "transition-all",
                    "border border-white/10"
                  ].join(" ")
                }}
              />
              {deleteError && (
                <p className="text-danger text-sm mt-2">{deleteError}</p>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                variant="light"
                onPress={() => !isDeleting && setIsReauthModalOpen(false)}
                isDisabled={isDeleting}
              >
                Cancel
              </Button>
              <Button
                color="danger"
                variant="flat"
                onPress={handleReauthenticate}
                isLoading={isDeleting}
              >
                Confirm Delete
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* Email Update Modal */}
        <Modal 
          isOpen={isEmailModalOpen} 
          onClose={() => !isUpdating && setIsEmailModalOpen(false)}
          classNames={{
            base: "glass-morphism",
            header: "border-b border-white/10",
            body: "py-6",
            footer: "border-t border-white/10"
          }}
        >
          <ModalContent>
            <ModalHeader>
              <h3 className="text-xl font-semibold">Change Email</h3>
            </ModalHeader>
            <ModalBody>
              <div className="space-y-4">
                <Input
                  type="email"
                  label="New Email"
                  value={newEmail}
                  onChange={(e) => setNewEmail(e.target.value)}
                  isDisabled={isUpdating}
                  classNames={{
                    label: "text-foreground/70",
                    input: "bg-background/50 text-foreground",
                    inputWrapper: [
                      "bg-background/50",
                      "backdrop-blur-xl",
                      "hover:bg-background/60",
                      "group-data-[focused=true]:bg-background/70",
                      "!cursor-text",
                      "transition-all",
                      "border border-white/10"
                    ].join(" ")
                  }}
                />
                <Input
                  type="password"
                  label="Current Password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  isDisabled={isUpdating}
                  classNames={{
                    label: "text-foreground/70",
                    input: "bg-background/50 text-foreground",
                    inputWrapper: [
                      "bg-background/50",
                      "backdrop-blur-xl",
                      "hover:bg-background/60",
                      "group-data-[focused=true]:bg-background/70",
                      "!cursor-text",
                      "transition-all",
                      "border border-white/10"
                    ].join(" ")
                  }}
                />
                {emailError && (
                  <motion.p 
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="text-danger text-sm"
                  >
                    {emailError}
                  </motion.p>
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="light"
                onPress={() => {
                  if (!isUpdating) {
                    setIsEmailModalOpen(false)
                    setNewEmail('')
                    setCurrentPassword('')
                    setEmailError('')
                  }
                }}
                isDisabled={isUpdating}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="flat"
                onPress={handleEmailUpdate}
                isLoading={isUpdating}
                isDisabled={!newEmail || !currentPassword}
              >
                Update Email
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* Password Update Modal */}
        <Modal 
          isOpen={isPasswordModalOpen} 
          onClose={() => !isUpdating && setIsPasswordModalOpen(false)}
          classNames={{
            base: "glass-morphism",
            header: "border-b border-white/10",
            body: "py-6",
            footer: "border-t border-white/10"
          }}
        >
          <ModalContent>
            <ModalHeader>
              <h3 className="text-xl font-semibold">Change Password</h3>
            </ModalHeader>
            <ModalBody>
              <div className="space-y-4">
                <Input
                  type="password"
                  label="Current Password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  isDisabled={isUpdating}
                  classNames={{
                    label: "text-foreground/70",
                    input: "bg-background/50 text-foreground",
                    inputWrapper: [
                      "bg-background/50",
                      "backdrop-blur-xl",
                      "hover:bg-background/60",
                      "group-data-[focused=true]:bg-background/70",
                      "!cursor-text",
                      "transition-all",
                      "border border-white/10"
                    ].join(" ")
                  }}
                />
                <Input
                  type="password"
                  label="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  isDisabled={isUpdating}
                  classNames={{
                    label: "text-foreground/70",
                    input: "bg-background/50 text-foreground",
                    inputWrapper: [
                      "bg-background/50",
                      "backdrop-blur-xl",
                      "hover:bg-background/60",
                      "group-data-[focused=true]:bg-background/70",
                      "!cursor-text",
                      "transition-all",
                      "border border-white/10"
                    ].join(" ")
                  }}
                />
                <Input
                  type="password"
                  label="Confirm New Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  isDisabled={isUpdating}
                  classNames={{
                    label: "text-foreground/70",
                    input: "bg-background/50 text-foreground",
                    inputWrapper: [
                      "bg-background/50",
                      "backdrop-blur-xl",
                      "hover:bg-background/60",
                      "group-data-[focused=true]:bg-background/70",
                      "!cursor-text",
                      "transition-all",
                      "border border-white/10"
                    ].join(" ")
                  }}
                />
                {passwordError && (
                  <motion.p 
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="text-danger text-sm"
                  >
                    {passwordError}
                  </motion.p>
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="light"
                onPress={() => {
                  if (!isUpdating) {
                    setIsPasswordModalOpen(false)
                    setCurrentPassword('')
                    setNewPassword('')
                    setConfirmPassword('')
                    setPasswordError('')
                  }
                }}
                isDisabled={isUpdating}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="flat"
                onPress={handlePasswordUpdate}
                isLoading={isUpdating}
                isDisabled={!currentPassword || !newPassword || !confirmPassword}
              >
                Update Password
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </PageTransition>
    </PageContainer>
  )
}

interface SettingItemProps {
  icon: React.ReactNode
  title: string
  description: string
  children: React.ReactNode
}

function SettingItem({ icon, title, description, children }: SettingItemProps) {
  return (
    <motion.div 
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      className="glass-morphism rounded-2xl p-4 flex items-center justify-between gap-4 hover-lift"
    >
      <div className="flex items-center gap-4">
        <div className="p-2 rounded-xl bg-primary/10 text-primary">
          {icon}
        </div>
        <div>
          <h3 className="font-medium text-foreground/90">{title}</h3>
          <p className="text-sm text-foreground/60">{description}</p>
        </div>
      </div>
      {children}
    </motion.div>
  )
}