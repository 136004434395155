import { db } from '@/lib/utils/firebase'
import { 
  collection, 
  addDoc, 
  updateDoc, 
  doc, 
  Timestamp, 
  deleteDoc,
  getDocs,
  query,
  where,
  orderBy,
  CollectionReference,
  DocumentData,
  getDoc
} from 'firebase/firestore'
import { auth } from '@/lib/utils/firebase'
import type { MoodEntry } from '@/types/mood'
import { getApp } from 'firebase/app'
import { stripe } from './stripe'

interface CreateMoodEntryInput {
  userId: string
  date: string
  time: string
  moodScore: number
  notes?: string
}

export async function createMoodEntry(entry: CreateMoodEntryInput) {
  try {
    const currentUser = auth.currentUser
    if (!currentUser) {
      console.error('Authentication error: No current user')
      throw new Error('User not authenticated')
    }
    
    const token = await currentUser.getIdToken()
    console.log('Auth token exists:', !!token)
    
    if (entry.userId !== currentUser.uid) {
      console.error('User ID mismatch:', { 
        currentUid: currentUser.uid, 
        entryUid: entry.userId 
      })
      throw new Error('User ID mismatch')
    }

    // Get subscription status
    const subscriptionStatus = await stripe.getSubscriptionStatus()
    
    // Parse the date and time in local timezone
    const [year, month, day] = entry.date.split('-').map(Number)
    const [hours, minutes] = entry.time.split(':').map(Number)
    const entryDateTime = new Date(year, month - 1, day, hours, minutes)
    
    if (isNaN(entryDateTime.getTime())) {
      throw new Error('Invalid date/time format')
    }
    
    // Ensure the date is not in the future
    const now = new Date()
    if (entryDateTime > now) {
      entryDateTime.setFullYear(now.getFullYear())
      entryDateTime.setMonth(now.getMonth())
      entryDateTime.setDate(now.getDate())
    }

    // For free users: manage storage limit
    if (!subscriptionStatus.isActive) {
      const moodRef = collection(db, 'users', currentUser.uid, 'moods');
      const querySnapshot = await getDocs(moodRef);
      
      // Create a map of dates to their entries
      const entriesByDate = new Map<string, { id: string; timestamp: Timestamp }[]>();
      querySnapshot.docs.forEach(doc => {
        const data = doc.data();
        const date = data.date;
        if (!entriesByDate.has(date)) {
          entriesByDate.set(date, []);
        }
        entriesByDate.get(date)?.push({ 
          id: doc.id, 
          timestamp: data.timestamp 
        });
      });

      // If this is a new date and we're at the limit, remove the oldest day's entries
      if (!entriesByDate.has(entry.date) && entriesByDate.size >= 5) {
        const dates = Array.from(entriesByDate.keys()).sort();
        const oldestDate = dates[0];
        const oldestEntries = entriesByDate.get(oldestDate) || [];
        
        // Delete all entries from the oldest date
        for (const oldEntry of oldestEntries) {
          const docRef = doc(db, 'users', currentUser.uid, 'moods', oldEntry.id);
          await deleteDoc(docRef);
        }
      }
    }

    console.log('Creating entry with datetime:', {
      input: { date: entry.date, time: entry.time },
      parsed: entryDateTime.toISOString()
    })
    
    const entryTimestamp = Timestamp.fromDate(entryDateTime)
    const nowTimestamp = Timestamp.fromDate(now)
    
    // Use user's subcollection for moods
    const moodRef = collection(db, 'users', currentUser.uid, 'moods')
    
    const moodData = {
      date: entry.date,
      time: entry.time,
      moodScore: Number(entry.moodScore),
      notes: entry.notes || '',
      timestamp: entryTimestamp,
      createdAt: nowTimestamp
    }
    
    console.log('Attempting to create mood entry with data:', moodData)
    
    const docRef = await addDoc(moodRef, moodData)
    console.log('Successfully created mood entry:', docRef.id)
    
    return { success: true, data: { id: docRef.id, ...moodData } }
    
  } catch (error) {
    console.error('Firebase operation error:', {
      error,
      code: error instanceof Error ? (error as any).code : 'unknown',
      name: error instanceof Error ? error.name : 'unknown',
      message: error instanceof Error ? error.message : 'unknown'
    })
    throw new Error(error instanceof Error ? error.message : 'Failed to create mood entry')
  }
}

export async function cleanupOldEntries() {
  try {
    const currentUser = auth.currentUser;
    if (!currentUser) return;

    // Get subscription status
    const subscriptionStatus = await stripe.getSubscriptionStatus();
    if (subscriptionStatus.isActive) return; // Only cleanup for free users

    // Check if user has lifetime premium
    const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
    const userData = userDoc.data();
    if (userData?.lifetimePremium === true) return;

    const moodRef = collection(db, 'users', currentUser.uid, 'moods');
    const querySnapshot = await getDocs(moodRef);
    
    // Get all entries sorted by date
    const entriesByDate = new Map<string, { id: string; timestamp: Timestamp }[]>();
    querySnapshot.docs.forEach(doc => {
      const data = doc.data();
      const date = data.date;
      if (!entriesByDate.has(date)) {
        entriesByDate.set(date, []);
      }
      entriesByDate.get(date)?.push({ 
        id: doc.id, 
        timestamp: data.timestamp 
      });
    });

    // Sort dates from newest to oldest
    const sortedDates = Array.from(entriesByDate.keys()).sort().reverse();

    // Keep only the 5 most recent days
    const datesToDelete = sortedDates.slice(5);
    
    // Delete entries from old dates
    for (const date of datesToDelete) {
      const entries = entriesByDate.get(date) || [];
      for (const entry of entries) {
        const docRef = doc(db, 'users', currentUser.uid, 'moods', entry.id);
        await deleteDoc(docRef);
      }
    }

  } catch (error) {
    console.error('Error cleaning up old entries:', error);
  }
}

export async function updateMoodEntry(id: string, updates: Partial<MoodEntry>) {
  try {
    const currentUser = auth.currentUser
    if (!currentUser) throw new Error('User not authenticated')

    const moodRef = doc(db, 'users', currentUser.uid, 'moods', id)

    // Only update timestamp if time is being updated and is defined
    if ('time' in updates && updates.time) {
      // Get the current document to access its date
      const docSnap = await getDoc(moodRef)
      if (!docSnap.exists()) throw new Error('Entry not found')
      
      const currentData = docSnap.data()
      const date = currentData.date
      
      // Parse the date and new time
      const [year, month, day] = date.split('-').map(Number)
      const [hours, minutes] = updates.time.split(':').map(Number)
      const newDateTime = new Date(year, month - 1, day, hours, minutes)
      
      if (isNaN(newDateTime.getTime())) {
        throw new Error('Invalid date/time format')
      }

      // Update both time and timestamp
      updates.timestamp = Timestamp.fromDate(newDateTime)
    }

    await updateDoc(moodRef, updates)
    return { success: true }
  } catch (error) {
    console.error('Error updating mood entry:', error)
    throw error
  }
}

export async function deleteMoodEntry(id: string) {
  try {
    const currentUser = auth.currentUser
    if (!currentUser) throw new Error('User not authenticated')

    const moodRef = doc(db, 'users', currentUser.uid, 'moods', id)
    await deleteDoc(moodRef)
    return { success: true }
  } catch (error) {
    console.error('Error deleting mood entry:', error)
    throw error
  }
}

export async function getUserMoods(userId: string, startDate?: string, endDate?: string) {
  try {
    const moodsRef = collection(db, 'users', userId, 'moods')
    let q = query(moodsRef, orderBy('date', 'desc'))

    if (startDate) {
      q = query(q, where('date', '>=', startDate))
    }
    if (endDate) {
      q = query(q, where('date', '<=', endDate))
    }

    const querySnapshot = await getDocs(q)
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as MoodEntry[]
  } catch (error) {
    console.error('Error fetching user moods:', error)
    throw error
  }
}