import { useEffect } from 'react'
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { AuthProvider } from '@/stores/auth-context'
import { ThemeProvider } from '@/stores/theme-context'
import { SubscriptionProvider } from '@/stores/subscription-context'
import { NextUIProvider } from '@nextui-org/react'
import MainLayout from '@/layouts/main-layout'
import { MobileLayout } from '@/layouts/mobile-layout'
import AuthPage from '@/pages/auth'
import HomePage from '@/pages/home'
import InputPage from '@/pages/input'
import DayPage from '@/pages/day'
import ProfilePage from '@/pages/profile'
import SettingsPage from '@/pages/settings'
import MigratePage from '@/pages/migrate'
import { ProtectedRoute } from '@/components/auth/protected-route'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// ScrollToTop component to force scroll reset
function ScrollToTop() {
  const location = useLocation()
  
  useEffect(() => {
    window.scrollTo(0, 0)
    document.body.scrollTo(0, 0)
    
    // Force all scrollable elements to top
    document.querySelectorAll('.scrollbar-hide').forEach(element => {
      if (element instanceof HTMLElement) {
        element.scrollTop = 0
      }
    })
  }, [location.pathname])

  return null
}

export default function App() {
  return (
    <ThemeProvider>
      <AuthProvider>
        <SubscriptionProvider>
          <NextUIProvider>
            <div className="min-h-screen bg-background">
              <ScrollToTop />
              <Routes>
                <Route path="/auth" element={<AuthPage />} />
                <Route path="/" element={<Navigate to="/auth" replace />} />
                
                <Route element={
                  <ProtectedRoute>
                    <MobileLayout />
                  </ProtectedRoute>
                }>
                  <Route path="/home" element={<HomePage />} />
                  <Route path="/input" element={<InputPage />} />
                  <Route path="/day" element={<DayPage />} />
                  <Route path="/profile" element={<ProfilePage />} />
                  <Route path="/settings" element={<SettingsPage />} />
                  <Route path="/migrate" element={<MigratePage />} />
                </Route>
              </Routes>
              <ToastContainer 
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
              />
            </div>
          </NextUIProvider>
        </SubscriptionProvider>
      </AuthProvider>
    </ThemeProvider>
  )
}
