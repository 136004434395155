import { useEffect, useState } from 'react';
import { Card, Button, Progress } from '@nextui-org/react';
import { useSubscription } from '@/stores/subscription-context';
import { useAuth } from '@/stores/auth-context';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '@/lib/utils/firebase';
import { cleanupOldEntries } from '@/services/mood';
import { useQueryClient } from '@tanstack/react-query';

export function StorageIndicator() {
  const { status } = useSubscription();
  const { user } = useAuth();
  const [uniqueDays, setUniqueDays] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isLifetimePremium, setIsLifetimePremium] = useState(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    async function checkLifetimePremium() {
      if (!user) return;
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      const userData = userDoc.data();
      setIsLifetimePremium(userData?.lifetimePremium === true);
    }

    async function countUniqueDays() {
      if (!user || status.isActive || isLifetimePremium) {
        setIsLoading(false);
        return;
      }

      try {
        const moodRef = collection(db, 'users', user.uid, 'moods');
        const querySnapshot = await getDocs(moodRef);
        const uniqueDates = new Set(
          querySnapshot.docs.map(doc => doc.data().date)
        );
        setUniqueDays(uniqueDates.size);
      } catch (error) {
        console.error('Error counting unique days:', error);
      } finally {
        setIsLoading(false);
      }
    }

    // Count unique days and cleanup old entries
    async function initialize() {
      await checkLifetimePremium();
      await cleanupOldEntries();
      await countUniqueDays();
      // Invalidate queries to refresh the UI
      queryClient.invalidateQueries({ queryKey: ['dailyMoods'] });
      queryClient.invalidateQueries({ queryKey: ['monthlyMoods'] });
      queryClient.invalidateQueries({ queryKey: ['moodStats'] });
    }

    initialize();
  }, [user, status.isActive, queryClient, isLifetimePremium]);

  if (status.isActive || isLifetimePremium || isLoading || uniqueDays === 0) {
    return null;
  }

  const daysRemaining = 5 - uniqueDays;
  const usagePercentage = (uniqueDays / 5) * 100;

  return (
    <Card className="w-full p-4 space-y-3">
      <div className="space-y-2">
        <h3 className="text-lg font-semibold">Storage Usage</h3>
        <p className="text-sm text-foreground/70">
          {daysRemaining === 0 
            ? "You've reached the storage limit for free users" 
            : `${daysRemaining} day${daysRemaining === 1 ? '' : 's'} of storage remaining`
          }
        </p>
      </div>
      <Progress 
        value={usagePercentage}
        color={usagePercentage >= 80 ? "danger" : "primary"}
        className="w-full"
      />
      <p className="text-xs text-foreground/60">
        Free users can store entries for up to 5 days. Older entries will be automatically removed.
      </p>
    </Card>
  );
}
