import { type ReactNode } from 'react'

interface PageContainerProps {
  children: ReactNode
  className?: string
}

export function PageContainer({ children, className = '' }: PageContainerProps) {
  return (
    <div className={`mx-auto w-full max-w-7xl space-y-8 px-4 py-8 lg:px-8 lg:py-12 ${className}`}>
      {children}
      {/* Extra padding div at the bottom */}
      <div className="h-24" aria-hidden="true" />
    </div>
  )
}
