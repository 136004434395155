import { useState, useEffect } from 'react';
import { Card, Button, Spinner } from '@nextui-org/react';
import { useSubscription } from '@/stores/subscription-context';
import { formatDate } from '@/lib/utils/date';
import { useAuth } from '@/stores/auth-context';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '@/lib/utils/firebase';

export function SubscriptionCard() {
  const { status, isLoading, createCheckoutSession, createPortalSession } = useSubscription();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLifetimePremium, setIsLifetimePremium] = useState(false);
  const [isSpecialUser, setIsSpecialUser] = useState(false);
  const { user } = useAuth();

  // Check if user has lifetime premium
  useEffect(() => {
    async function checkLifetimePremium() {
      if (!user) return;
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      const userData = userDoc.data();
      setIsLifetimePremium(userData?.lifetimePremium === true);
      setIsSpecialUser(user.uid === 'SXuiCQn04WfTuqlukWgiKkx7Bw23');
    }
    checkLifetimePremium();
  }, [user]);

  const handleSubscribe = async () => {
    setIsProcessing(true);
    try {
      await createCheckoutSession(import.meta.env.VITE_STRIPE_PRICE_ID);
    } catch (error) {
      console.error('Error creating checkout session:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleManageSubscription = async () => {
    if (!user) return;
    setIsProcessing(true);
    try {
      await createPortalSession(user);
    } catch (error) {
      console.error('Error creating portal session:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  if (isLoading) {
    return (
      <Card className="w-full p-4">
        <div className="flex items-center justify-center">
          <Spinner size="sm" />
        </div>
      </Card>
    );
  }

  if (isLifetimePremium) {
    return (
      <Card className="w-full p-4 space-y-4">
        <div className="space-y-2">
          <h3 className="text-lg font-semibold">
            {isSpecialUser ? '🖤 Thank You' : 'Lifetime Premium Access'}
          </h3>
          {isSpecialUser ? (
            <div className="space-y-2">
              <p className="text-sm text-foreground/70">
                Thank you for being by my side through every up and down while building this pipe dream of mine.
                Your support means everything to me. I love you! 🖤
              </p>
              <p className="text-sm text-foreground/70">
                You have lifetime premium access to all features.
              </p>
            </div>
          ) : (
            <p className="text-sm text-foreground/70">
              You have unlimited access to all premium features.
            </p>
          )}
        </div>
      </Card>
    );
  }

  return (
    <Card className="w-full p-4 space-y-4">
      <div className="space-y-2">
        <h3 className="text-lg font-semibold">
          {status.isActive ? 'Premium Subscription' : 'Upgrade to Premium'}
        </h3>
        <p className="text-sm text-foreground/70">
          {status.isActive
            ? status.cancelAtPeriodEnd
              ? `Your subscription will end on ${formatDate(status.periodEnd)}`
              : `Your subscription will renew on ${formatDate(status.periodEnd)}`
            : 'Unlock premium features with a subscription'}
        </p>
      </div>

      <Button
        color={status.isActive ? 'default' : 'primary'}
        variant="flat"
        onPress={status.isActive ? handleManageSubscription : handleSubscribe}
        isLoading={isProcessing}
        className="w-full"
      >
        {status.isActive ? 'Manage Subscription' : 'Subscribe Now'}
      </Button>
    </Card>
  );
}
